import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import SiteHeader from '../site-header/site-header';
import './reset.scss';
import './layout.scss';
import './prism.scss';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <SiteHeader siteTitle={data.site.siteMetadata.title} />
        {children}
      </>
    )}
  />
);

export default Layout;
