import { Link } from 'gatsby';
import React from 'react';
import Logo from '../logo';
import './site-header.scss';

const NavLink = ({ title, to }) => (
  <li>
    <Link to={to}>{title}</Link>
  </li>
);

const Zap = () => (
  <Logo name="images/bolt.png" />
);

const SiteHeader = ({ siteTitle }) => (
  <div className="site-header">
    <div className="site-header__container">
      <h1 className="site-header-title">
        <Link to="/">Simplified <Zap /></Link>
      </h1>
      <nav className="site-header-nav">
        <ul>
          {/* <NavLink to="/performance" title="Performance" /> */}
          {/* <NavLink to="/training" title="Training" /> */}
          <NavLink to="/contact" title="Contact" />
        </ul>
      </nav>
    </div>
  </div>
);

SiteHeader.defaultProps = {
  siteTitle: ''
};

export default SiteHeader;
